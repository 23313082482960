.card-social svg {
  height: 25px;
}

.card-social {
  position: relative;
  border-left: 3px solid #1e1e1e;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: center;
  background: #e7e7e7;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  height: 35px;
  width: 200px;
}

.card-social::before, .card-social::after {
  position: absolute;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  transition: 0.25s linear;
  z-index: 1;
}

.card-social::before {
  content: "";
  left: 0;
  justify-content: flex-end;
  background-color: #6490bc;
}

.card-social::after {
  content: "";
  right: 0;
  justify-content: flex-start;
  background-color: #6490bc;
}

.card-social:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-social:hover span {
  opacity: 0;
  font-family: 'Belanosima';
  z-index: -3;
}

.card-social:hover::before {
  opacity: 0.5;
  transform: translateY(-100%);
}

.card-social:hover::after {
  opacity: 0.5;
  transform: translateY(100%);
}

.card-social span {
  position: absolute;
  display: flex;
  font-family: 'Belanosima';
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: whitesmoke;
  font-size: 20px;
  font-weight: 400;
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 2;
  animation: rotateBounce 2s infinite;
}

.card-social .social-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
  color: whitesmoke;
  font-size: 24px;
  text-decoration: none;
  transition: 0.25s;
}

.card-social .social-link svg {
  text-shadow: 1px 1px rgba(31, 74, 121, 0.7);
  transform: scale(1);
}

.card-social .social-link:hover {
  background-color: rgba(249, 244, 255, 0.774);
  animation: bounce_613 0.4s linear;
}

@keyframes bounce_613 {
  40% {
    transform: scale(1.4);
  }
  60% {
    transform: scale(0.8);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotateBounce {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(2deg);
  }
  25%, 75% {
    transform: rotate(-2deg);
  }
}
