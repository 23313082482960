.email-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    padding: 2rem;
    width: 100%;
    background-color: #121212;
    color: white;
}

.email-title {

    color: white;
}

.email-card {
    background-color: #1e1e1e;
    color: white;
    margin-bottom: 1rem;
    transition: transform 0.3s ease;
}

.email-card:hover {
    transform: scale(1.02);
}

.email-card-content {
    padding: 1rem;
}

.email-card-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.email-card-body {
    font-size: 1rem;
    color: #ccc;
}

.email-attachments {
    margin-top: 10px;
}

.email-attachments a {
    display: block;
    color: #6490BC;
    text-decoration: none;
    margin-top: 5px;
}

.email-attachments a:hover {
    text-decoration: underline;
}

.email-attachments img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: 10px;
    border-radius: 8px;
}

.email-card-date {
    font-size: 0.875rem;
    color: #999;
}

.email-thread-header {
    display: flex;

    justify-content: space-between;
    align-items: center;
}

.email-thread-content {
    
    padding: 1rem;
    border-top: 1px solid #444;
}

.email-expand-button {
    color: white;
}
