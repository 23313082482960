.blog-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 3rem;
}

.header {
    display: flex;
    justify-content: center;
    width: 100%;
}

.header-image {
    width: 500px;
    margin-top: 5rem;
    object-fit: contain;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.search-header {
    display: flex;
    margin-bottom: 0rem;
    gap: 5rem;
    margin-top: 1rem;
    align-items: center;
    width: a100;
    justify-content: center;
}

.logo-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.logo {
    margin-top: 7px;
    width: 80px;
    object-fit: contain;
}

.blog-title {
    color: #6490bc;
    font-size: 30px;

}

.blog-title.mobile {
    margin-top: 1rem;
    font-size: 24px;
}

.blog-icon {
    margin-left: 12px;
}

.search-container {
    width: auto;
}

.search-field {
    margin-top: 0.5rem;
    width: 350px;
}

.search-icon {
    color: white;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 2rem;
    width: 70%;
}

.card-blog {
    background-color: #1a1a1a;
    border: 1px solid rgba(100, 100, 111, 0.5);
    box-shadow: rgba(100, 100, 111, 0.7) 0px 7px 20px 0px;
    color: white;
    padding: 20px;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    transition: transform 0.3s;
}

.card-blog:hover {
    transform: scale(1.02);
}

.top-section {
    position: relative;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #6490bc;
}


.icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.logo svg {
    width: 50px;
    height: 50px;
}

.social-media {
    display: flex;
    gap: 10px;
}



.title {
    font-size: 18px;
    margin-bottom: 10px;
    display: block;
    margin-top: 0.5rem;
}

.row {
    display: flex;
    justify-content: space-between;
}

.item {
    text-align: center;
}

.big-text {
    font-size: 22px;
    display: flex;
    align-item: center;
}

.regular-text {
    font-size: 14px;
    color: #a0a0a0;
    display: flex;
    align-item: center;
}

.read-button {
    background-color: #6490bc;
    color: black;
    text-align: center;
    width: 100%;
    margin-top: 10px;
}
