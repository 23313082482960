.section-cards {
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .section-card {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 4rem;
    height: 250px;
    background-color: #f2f2f2;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 0 5px #ffffff80;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .section-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .section-card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .section-card:hover .section-card__overlay {
    opacity: 0;

  }
  
  .section-card__title {
    color: white;
    font-size: 24px;
    text-align: center;
    padding: 0 10px;
  }
  
  .section-card__content {
    position: absolute;
    top: 0;
    flex-direction: column;
    left: 0;
    width: 100%;
    color: white;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.8);
    transform: rotateX(-90deg);
    transform-origin: bottom;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    opacity: 0;
  }
  
  .section-card:hover .section-card__content {
    transform: rotateX(0deg);
    opacity: 1;
  }
  

  
  .section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin: 40px 0;
    width: 100%;
  }
  
  .section.reverse {
    flex-direction: row-reverse;
  }
  
  .section img {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    border-bottom: 5px solid white;
  }
  
  .section-text {
    max-width: 600px;
  }
  

  
  @media (max-width: 600px) {
    .section {
      flex-direction: column;
      align-items: center;
    }
  
    .section.reverse {
      flex-direction: column;
    }
  
    .section img {
      max-width: 100%;
    }
  }
  



  .flotta-container {
    display: flex;
    width: 85%;
    justify-content: space-around;
    margin-bottom: 3rem;
  }

  .card-flotta {
    width: 230px;
    border-radius: 20px;
    background: #121212;
    border: 1px solid rgba(100, 100, 111, 0.5);
    padding: 15px;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card-flotta:hover {
    transform: scale(1.05);
  }
  
  .card-flotta .top-section {
    height: 150px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(45deg, rgb(4, 159, 187) 0%, rgb(80, 246, 255) 100%);
    position: relative;
  }
  
  .card-flotta .top-section .border {
    border-bottom-right-radius: 10px;
    height: 30px;
    width: 130px;
    background: white;
    background: #1b233d;
    position: relative;
    transform: skew(-40deg);
    box-shadow: -10px -10px 0 0 #1b233d;
  }
  
  .card-flotta .top-section .border::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    right: -15px;
    background: rgba(255, 255, 255, 0);
    border-top-left-radius: 10px;
    box-shadow: -5px -5px 0 2px #1b233d;
  }
  
  .card-flotta .top-section::before {
    content: "";
    position: absolute;
    top: 30px;
    left: 0;
    background: rgba(255, 255, 255, 0);
    height: 15px;
    width: 15px;
    border-top-left-radius: 15px;
    box-shadow: -5px -5px 0 2px #1b233d;
  }
  
  .card-flotta .top-section .icons {
    position: absolute;
    top: 0;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
  }
  
  .card-flotta .top-section .icons .logo {
    height: 100%;
    aspect-ratio: 1;
    padding: 7px 0 7px 15px;
  }
  
  .card-flotta .top-section .icons .logo .top-section {
    height: 100%;
  }
  
  .card-flotta .top-section .icons .social-media {
    height: 100%;
    padding: 8px 15px;
    display: flex;
    gap: 7px;
  }
  
  .card-flotta .top-section .icons .social-media .svg {
    height: 100%;
    fill: #1b233d;
  }
  
  .card-flotta .top-section .icons .social-media .svg:hover {
    fill: white;
  }
  
  .card-flotta .bottom-section {
    margin-top: 5px;
    padding: 10px 5px;
  }
  
  .card-flotta .bottom-section .title {
    display: block;
    font-size: 18px;
    font-weight: bolder;
    color: white;
    text-align: center;
    letter-spacing: 2px;
  }
  
  .card-flotta .bottom-section .row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .card-flotta .bottom-section .row .item {
    flex: 30%;
    text-align: center;
    padding: 5px;
    color: rgba(170, 222, 243, 0.721);
  }
  
  .card-flotta .bottom-section .row .item .big-text {
    font-size: 13px;
    display: block;
  }
  
  .card-flotta .bottom-section .row .item .regular-text {
    font-size: 11px;
    color: #6490bc;
  }
  
  .card-flotta .bottom-section .row .item:nth-child(2) {
    border-left: 1px solid rgba(255, 255, 255, 0.126);
    border-right: 1px solid rgba(255, 255, 255, 0.126);
  }

  .card-flotta img 
  {
    border-radius: 0.5rem;
    width: 100%;
    height: 150px;
  }