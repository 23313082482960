.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  justify-content: center;
  margin-top: 5rem;
  color: white;
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);
}

.page-title {
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-align: center;
}

.page-content {
  text-align: center;
}

.card {
  width: 300px;
  height: 200px;
  background-color: #1b233d;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
}

.card:hover {
  transform: scale(1.05);
}

.card__image-container {
  position: relative;
  width: 100%;
  height: 120px;
  overflow: hidden;
}

.card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.card__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.card:hover .card__overlay {
  opacity: 1;
}

.card__content {
  padding: 1rem;
  background-color: #1b233d;
  color: white;
}

.card__title {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.card__discover {
  font-size: 18px;
  font-weight: bold;
  color: #6490bc;
  text-align: center;
}

.yacht-content-container {
  display: flex;
  padding: 20px;
  align-items: center;
  margin-top: 2rem;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  backdrop-filter: blur(5px);
}



.book {
  position: relative;
  border-radius: 10px;
  margin-top: 3rem;
  flex-direction: column;
  padding: 20px;
  height: 300px;
  background-color: whitesmoke;
  -webkit-box-shadow: 1px 1px 12px #000;
  box-shadow: 1px 1px 12px #000;
  -webkit-transform: preserve-3d;
  -ms-transform: preserve-3d;
  transform: preserve-3d;
  line-height: 20px;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  display: -webkit-box;
  margin-bottom: 3rem;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #000;
}

.cover {
  top: 0;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://www.luxuryagencyrome.it/static/media/ponza.210bdebc88bf47674eae.webp');
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  transform-origin: 0;
  -webkit-box-shadow: 1px 1px 12px #000;
  box-shadow: 1px 1px 12px #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.book:hover .cover {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: rotatey(-100deg);
  -ms-transform: rotatey(-100deg);
  transform: rotatey(-100deg);
}

p {
  font-size: 70px;
  color: white;

  text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;

  font-family: 'Playfair Display';

}

.book-inside-text {
  font-size: 18px;
  text-shadow: none;
  font-family: 'Belanosima';
  font-style: italic;
  color: black;
}

.book-button {
  z-index: -1;
}
