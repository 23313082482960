@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Belanosima:wght@400;600;700&display=swap');

body {
  // font-family: "Playfair Display", serif;
  font-family: "Great Vibes", cursive;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #161616;
}

.hover-container {
  position: relative;
}

.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
  color: black;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 0.5rem;
}

@keyframes slideIn {
  0% { opacity: 0; transform: translateY(20px); }
  50% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-20px); }
}


span {
  font-family: Belanosima;
}
.hover-container:hover .hover-overlay {
  opacity: 1;
}



::-webkit-scrollbar {
  width: 8px;
  height: 8px; 
}

/* Track style */
::-webkit-scrollbar-track {
  background-color: black;
  border-radius: 10px;
}

/* Handle style */
::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px; 
  border: 2px solid #6490bc; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: black; 
}


